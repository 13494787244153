import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';


const Cell = styled.div`
    display: flex;
    flex-direction: column;
    width: 13%;
    justify-content:center;
    align-items:center;
    margin-right:3%;


    @media screen and (max-width: 320px) {
        width: 50%;
        margin-right:0%;
    }

    @media (min-width: 360px) and (max-width: 1024px){
        justify-content: center;
        width: 45%;
    }
`;

const Image = styled(GatsbyImage)`
    flex-shrink: 0;
`;


const Title = styled.h3`
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    height:29%;

    @media screen and (max-width: 680px) {
       height:auto;
    }
`;

const Text = styled.h3`
    font-size: 14px;
    font-weight: 200;
    text-align: center;
    margin-top:0px;
`;

const ProfileCard = ({
    headerImage, title, text
}) => (
    <Cell>
        <Image image={headerImage.gatsbyImageData}
            alt='circle picture media' />
        <Title dangerouslySetInnerHTML={{__html: title}}></Title>
        <Text dangerouslySetInnerHTML={{__html: text}}></Text>
    </Cell>
);

ProfileCard.propTypes = {
    headerImage: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string
};

export default React.memo(ProfileCard);
