import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';


const Cell = styled.div`
    display:flex;
    flex-direction:row;
    width:21%;
    align-items:center;
    margin:2%;

    @media screen and (max-width: 680px) {
        flex-direction: column;
        width: 40%;
        margin: 5%;
        text-align: center;
    }

    @media (min-width: 681px) and (max-width: 1024px){
        justify-content: center;
        width: 45%;
    }
`;

const ProfileImage = styled.a`
    flex-grow: 1;
    margin-right:20px;
    
    &:hover {
        filter: opacity(40%);
        transition: 0.5s;
    }

    @media screen and (max-width: 680px) {
        margin-right:0px;
    }

`;

const Image = styled(GatsbyImage)`
  filter: grayscale(100%);
  border-radius: 50%;
  height: 75px;
  width: 75px;
  object-fit: cover;
`;

const Description = styled.div`
    width:65%;

    @media screen and (max-width: 680px) {
        width:auto;
    }
`;

const Title = styled.h3`
   font-size:16px;
   font-weight:300;
   margin-top:0;
   margin-bottom:10px;
   
`;

const Text = styled.h3`
    font-size:12px;
    font-weight:300;
    margin-top:5px;
    margin-bottom:5px;
  
`;

const Location = styled.div`
    display:flex;
    flex-direction:row;

    @media screen and (max-width: 680px) {
        justify-content: center;
    }
    
`;

const Icon = styled.img`
  margin-right:5px;
  
  @media screen and (max-width: 320px) {
    display:none;
}
`;

const City = styled.p`
    font-size:11px;
    font-weight:200;
    margin-top:0;
    margin-bottom:0;
`;

const ProfileCardHorizontal = ({
    url,
    headerImage,
    title,
    text,
    cityLocation
}) => (
    <Cell>
        <ProfileImage href={url}
            target='_blank'
            rel="noopener">
            <Image image={headerImage.gatsbyImageData}
                alt="circle picture media" />
        </ProfileImage>
        <Description>
            <Title>{title}</Title>
            <Text>{text}</Text>
            <Location>
                <Icon src='/images/team/placeholder.svg'/>
                <City>{cityLocation}</City>
            </Location>
        </Description>
    </Cell>
);

ProfileCardHorizontal.propTypes = {
    url: PropTypes.string,
    headerImage: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
    cityLocation: PropTypes.string
};

export default React.memo(ProfileCardHorizontal);
