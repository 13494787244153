import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import ProfileCardHorizontal from '../components/ProfileCardHorizontal';
import ProfileCard from '../components/ProfileCard';
import BannerPicture from '../components/BannerPicture';
import BackgroundPicture from '../components/BackgroundPicture';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import { GatsbyImage } from 'gatsby-plugin-image';

const ProfileCardHorizontalLayout = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    margin: 2% 0% 7% 0%;

    @media screen and (max-width: 680px) {
}
`;

const ProfileCardVerticalLayout = styled.div`
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        margin: 2% 0% 7% 0%;

    @media screen and (max-width: 680px) {
        justify-content: center;
        }
`;


const TitleCard = styled.h3`
        font-size:30px;
        font-weight:200;
        margin-top:7%;
`;

const ContactLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 7%;
    margin-right : -18%;
`;

const ContactContent = styled.div`
    display:flex;
    flex-direction:column;
    width:29%;

        @media screen and (max-width: 680px) {
        width:45%;
        margin-bottom:5%;
        }

`;
const TitleCity = styled.p`
        font-size:16px;
        font-weight:300;
        margin:0% 0%;
`;

const Address = styled.p`
        margin:0px;
        font-size:14px;
        line-height:19px;
        font-weight:200;

`;

const Phone = styled.p`
        font-size:14px;
        font-weight:200;
        color:#2FAAE1;
        margin:0% 0%;
`;

const ContainerSupport = styled.div`
    display: flex;
    flex-direction: column;
`;

const Subtitle = styled.h3`
    font-size: 18px;
    font-weight: 400;
`;

const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 50%;
    min-height: 200px;

    a { 
      color: white;
      margin-top: auto;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

`;

const Logo = styled(GatsbyImage)`
    width: 200px;
    height: 200px;
    margin-top: auto;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('about');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_TEAM')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_TEAM')}/>
            </Helmet>
            <Layout>
                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='team wemap'
                />

                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'>{t('TITLE')}</h1>

                    <SectionOnlyFooter
                        titleSection={t('BANNER_SUBTITLE')}
                        cellParagraph={t('BANNER_DESCRIPTION')}
                        pictureRight={data.arNavigation.childImageSharp}
                        altDescription='mobiles with a detailed pinpoint view and a outdoor augmented reality navigation'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        widthPicture='400px'
                        urlFooter='/products/'
                    />

                    <TitleCard>{t('Team Wemap')}</TitleCard>
                    <ProfileCardHorizontalLayout>
                        <ProfileCardHorizontal
                            headerImage={data.manu.childImageSharp}
                            title={t('Manu')}
                            text={t('Co-Founder')}
                            cityLocation={t('Paris, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.julie.childImageSharp}
                            title={t('Julie')}
                            text={t('Office Manager')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.adelinec.childImageSharp}
                            title={t('Adeline')}
                            text={t('Customer Success Manager')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.david.childImageSharp}
                            title={t('David')}
                            text={t('Commercial Director')}
                            cityLocation={t('Paris, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.bertrand.childImageSharp}
                            url='https://blog.getwemap.com/meet-bertrand-4749e9cbc1d4'
                            title={t('Bertrand')}
                            text={t('Chief Technical Officer')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.thibaud.childImageSharp}
                            url='https://blog.getwemap.com/bienvenue-thibaud-53b7c657acca'
                            title={t('Thibaud')}
                            text={t('Research and Innovation Manager')}
                            cityLocation={t('Grenoble, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.simon.childImageSharp}
                            title={t('Simon')}
                            text={t('Lead Front End Developer')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.mustapha.childImageSharp}
                            title={t('Mustapha')}
                            text={t('Mobile Developer')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.eugene.childImageSharp}
                            title={t('Eugene')}
                            text={t('Mobile Developer')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.adrian.childImageSharp}
                            title={t('Adrian')}
                            text={t('DevOps Engineer')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.yacine.childImageSharp}
                            title={t('Yacine')}
                            text={t('Computer Vision Researcher')}
                            cityLocation={t('Nice, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.lea.childImageSharp}
                            title={t('Léa')}
                            text={t('Head of design')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.hector.childImageSharp}
                            title={t('Hector')}
                            text={t('Map designer')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.konstantin.childImageSharp}
                            title={t('Konstantin')}
                            text={t('Map designer')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.tim.childImageSharp}
                            title={t('Tim')}
                            text={t('Head of business development')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.audrey.childImageSharp}
                            title={t('Audrey')}
                            text={t('Commercial')}
                            cityLocation={t('Paris, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.laurine.childImageSharp}
                            title={t('Laurine')}
                            text={t('Customer Success')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.laurent.childImageSharp}
                            title={t('Laurent')}
                            text={t('Customer Success')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.candice.childImageSharp}
                            title={t('Candice')}
                            text={t('Customer Success')}
                            cityLocation={t('Montpellier, France')} />

                        <ProfileCardHorizontal
                            headerImage={data.icon_plus.childImageSharp}
                            title={t('Maybe you ?')}
                            url='/contact'
                            cityLocation={t('Montpellier, France')} />

                    </ProfileCardHorizontalLayout>


                    <TitleCard>{t('Advisory Board')}</TitleCard>
                    <ProfileCardVerticalLayout>
                        <ProfileCard
                            headerImage={data.pierre.childImageSharp}
                            title={t('Pierre<br>Addoum')}
                            text={t('ARGO CEO')} />

                        <ProfileCard
                            headerImage={data.bertrands.childImageSharp}
                            title={t('Bertrand<br>Stephann')}
                            text={t('Alcméon CEO')} />

                        <ProfileCard
                            headerImage={data.ghislain.childImageSharp}
                            title={t('Ghislain<br>Lesaffre')}
                            text={t('CEO Chocmod')} />

                        <ProfileCard
                            headerImage={data.luc.childImageSharp}
                            title={t('Luc<br>Vincent')}
                            text={t('VP of AI at Meta')} />
                        <ProfileCard
                            headerImage={data.jeremy.childImageSharp}
                            title={t('Jérémy<br>Lainé')}
                            text={t('CTO Spacinov')} />

                    </ProfileCardVerticalLayout>

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with two people talking at desk'
                        titleBannerPicture={t('TITLE_BANNER_PICTURE_LOOKING_TALENT')}
                        titleButton={t('TITLE_CONTACT')}
                        buttonUrl='/contact/'
                    />

                    <ContainerSupport>
                        <Subtitle>{t('WEMAP_SUPPORTED_BY')}</Subtitle>
                        <LogoContainer>
                            <LogoWrapper>
                                <Logo image={data.fedeProOsm.childImageSharp.gatsbyImageData}
                                    alt='Fede Pro OSM' />
                                <a
                                    href="https://fposm.fr/"
                                    target="_blank"
                                    rel="noopener noreferrer">Fédération des pro d'OSM</a>
                            </LogoWrapper>
                            <LogoWrapper>
                                <Logo image={data.franceTourTech.childImageSharp.gatsbyImageData}
                                    alt='France Tourisme Tech' />
                                <a
                                    href="https://www.entreprises.gouv.fr/priorites-et-actions/proximite-et-territoires/renforcer-le-secteur-du-tourisme/france-tourisme-tech"
                                    target="_blank"
                                    rel="noopener noreferrer">France Tourisme Tech</a>
                            </LogoWrapper>
                        </LogoContainer>
                    </ContainerSupport>

                    <TitleCard>{t('Contact')}</TitleCard>
                    <ContactLayout>
                        <ContactContent>
                            <TitleCity className='wemap-container-contact-title'>{t('Wemap Montpellier')}</TitleCity>
                            <Address>{t('Parc Club du Millénaire Batiment 23, 34000 Montpellier')}</Address>
                            <Phone className='wemap-container-contact-content-phone'>{t('+33 7 68 74 42 34')}</Phone>
                        </ContactContent>

                        <ContactContent>
                            <TitleCity className='wemap-container-contact-title'>{t('Wemap Paris')}</TitleCity>
                            <Address>{t('3 cité Paradis 75010 Paris France')}</Address>
                            <Phone className='wemap-container-contact-content-phone'>{t('+33 7 68 74 42 34')}</Phone>
                        </ContactContent>
                    </ContactLayout>

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  background: file(relativePath: {eq: "background-image/team-bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  banner: file(relativePath: {eq: "team/banner_wemap_team.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  fedeProOsm: file(relativePath: {eq: "partners/fede-pro-osm.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 300, layout: FIXED)
    }
  }
  franceTourTech: file(relativePath: {eq: "partners/france-tourisme-tech.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 300, layout: FIXED)
    }
  }
  arNavigation: file(relativePath: {eq: "home/wemap-ar-navigation.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 600, layout: FIXED)
    }
  }
  pierre: file(relativePath: {eq: "team/pierre.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  manu: file(relativePath: {eq: "team/manu.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  salvatore: file(relativePath: {eq: "team/salvatore.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  jeremy: file(relativePath: {eq: "team/jeremy.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  julie: file(relativePath: {eq: "team/julie.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  adelinec: file(relativePath: {eq: "team/adelinec.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  david: file(relativePath: {eq: "team/david-2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  bertrand: file(relativePath: {eq: "team/bertrand.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  thibaud: file(relativePath: {eq: "team/thibaud.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  yacine: file(relativePath: {eq: "team/yacine.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  simon: file(relativePath: {eq: "team/simon.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  lea: file(relativePath: {eq: "team/lea.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  hector: file(relativePath: {eq: "team/hector.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  mustapha: file(relativePath: {eq: "team/mustapha.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  tim: file(relativePath: {eq: "team/tim.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  eugene: file(relativePath: {eq: "team/eugene.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  laurent: file(relativePath: {eq: "team/laurent.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  audrey: file(relativePath: {eq: "team/audrey.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  laurine: file(relativePath: {eq: "team/laurine.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  konstantin: file(relativePath: {eq: "team/konstantin.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, height: 75, layout: FIXED)
    }
  }
  adrian: file(relativePath: {eq: "team/adrian.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  candice: file(relativePath: {eq: "team/candice.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  icon_plus: file(relativePath: {eq: "team/icon_plus.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 74, layout: FIXED)
    }
  }
  bertrands: file(relativePath: {eq: "team/bertrands.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  christophe: file(relativePath: {eq: "team/christophe.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  julien: file(relativePath: {eq: "team/julien.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  ghislain: file(relativePath: {eq: "team/ghislain.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
  luc: file(relativePath: {eq: "team/luc.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 75, layout: FIXED)
    }
  }
}
`;
